.post_container {
  width: 100%;
  margin: auto;
  margin-top: 15px;
  background-color: white;
}
.postCard_container {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 5px 10px;
  border-radius: 6px;
  box-shadow: 0 0 2px black;
}

.post_title_container,
.post_text_container,
.post_info_container {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  margin-bottom: 8px;
}
.post_title_container h3 {
  margin-right: 5px;
  font-size: 16px;
}
.post_title_container h4 {
  font-weight: 400;
  font-size: 12px;
}

.post_text_container h5 {
  font-weight: 450;
  font-size: 14px;
}

.post_media_container {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 10px;
  max-height: 300px;
  overflow: hidden;
  border-radius: 3px;
}
.post_media_container img {
  width: 100%;
  border-radius: 3px;
}

.post_info_container {
  margin-bottom: 0;
}
