.signUpPage_Container {
  padding: 20px;
  margin-top: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.signUpPage_Container form {
  background-color: rgb(243, 243, 243);
}

.signUpPage_Container form {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  box-shadow: 0 0 2px black;
  border-radius: 3px;
  padding: 15px 30px;
}
