.homePage_container {
  width: 80%;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  margin: auto;
  max-width: 850px;
}

.userFeed_container,
.userInfo_container {
  background-color: rgb(243, 243, 243);
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
  margin: 20px;
  padding: 10px;
  border-radius: 5px;
  box-shadow: 0 0 2px black;
}

.userFeed_container {
  padding: 15px;
  margin-right: 0;
  flex: 1;
}

.userInfo_container {
  max-width: 250px;
}
